import React from "react";
import "./merchandise.css";
import Navigation from "../../components/Navbar/Navigation";
import { BsDot } from "react-icons/bs";

import kaos1 from "../../assets/merch/kaos-1.jpeg";
import kaos2 from "../../assets/merch/kaos-2.jpeg";
import kaos3 from "../../assets/merch/kaos-3.jpeg";
import kaos4 from "../../assets/merch/kaos-4.jpeg";
import kaos5 from "../../assets/merch/kaos-5.jpeg";
import kaos6 from "../../assets/merch/kaos-6.jpeg";
import topi1 from "../../assets/merch/topi-1.jpeg";
import topi2 from "../../assets/merch/topi-2.jpeg";
import topi3 from "../../assets/merch/topi-3.jpeg";
import topi4 from "../../assets/merch/topi-4.jpeg";
import topi5 from "../../assets/merch/topi-5.jpeg";
import topi6 from "../../assets/merch/topi-6.jpeg";

const Data = [
  {
    id: 1,
    imgSrc: kaos1,
    descTitle: "Kaos",
    location: "Flora & Fauna of Kalimantan",
    grade: "unknown",
    //   navigation: "/forest-page",
  },
  {
    id: 2,
    imgSrc: kaos2,
    descTitle: "Kaos",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 3,
    imgSrc: kaos3,
    descTitle: "Kaos",
    location: "Kalimantan Selatan",
    grade: " unknown",
    //   navigation: "/mountain-page",
  },
  {
    id: 4,
    imgSrc: kaos4,
    descTitle: "Kaos",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
  {
    id: 5,
    imgSrc: kaos5,
    descTitle: "Kaos",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
  {
    id: 6,
    imgSrc: kaos6,
    descTitle: "Kaos",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },

  {
    id: 7,
    imgSrc: topi1,
    descTitle: "topi",
    location: "Flora & Fauna of Kalimantan",
    grade: "unknown",
    //   navigation: "/forest-page",
  },
  {
    id: 8,
    imgSrc: topi2,
    descTitle: "topi",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 9,
    imgSrc: topi3,
    descTitle: "topi",
    location: "Kalimantan Selatan",
    grade: " unknown",
    //   navigation: "/mountain-page",
  },
  {
    id: 10,
    imgSrc: topi4,
    descTitle: "topi",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
  {
    id: 11,
    imgSrc: topi5,
    descTitle: "topi",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
  {
    id: 12,
    imgSrc: topi6,
    descTitle: "topi",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
];

const Merchandise = () => {
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="merchandise container section">
        <div className="secContainer">
          <div className="secHeader flex">
            <div className="textDiv">
              <h2 className="secTitle">Explore our Merchandise</h2>
              <p>
                Lorem Ipsum dolor sit amet, concestur adipiscing elit. Urna,
                tortor tempus.
              </p>
            </div>
          </div>

          <div className="mainContent grid">
            {Data.map(({ id, imgSrc, descTitle, location, grade }) => {
              return (
                <div className="singleDestination">
                  <div
                    className="destImage"
                    // onClick={() => navigate(navigation)}
                  >
                    <img src={imgSrc} alt="Image Title" />

                    <div className="overlayInfo">
                      <h3>{descTitle}</h3>
                      <p>{location}</p>
                      {/* <BsArrowRightShort className="icon" /> */}
                    </div>
                  </div>

                  <div className="destFooter">
                    {/* <div className="number">0{id}</div> */}

                    <div className="destText flex">
                      <h6>{descTitle}</h6>
                      <span className="flex">
                      <span className="dot">
                        <BsDot className="icon" />
                      </span>
                      Rp 100.000,-
                    </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Merchandise;

import React from "react";
import Navigation from "../../components/Navbar/Navigation";
import "./profile.css";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="profile section container ">
        <div className="detailProfileMaxWidth ">
          <div className="profile-detail">
            <div className="detail-info">
              <h1>Profile</h1>
              <h5 style={{ fontWeight: "bold" }}>Name</h5>
              <p>Wildan Harits</p>
              <h5 style={{ fontWeight: "bold" }}>Email</h5>
              <p>wildan@gmail.com</p>
              <h5 style={{ fontWeight: "bold" }}>Phone</h5>
              <p>085606021029</p>
            </div>
          </div>
          <div className="activity">
            <h1>Activity</h1>
            <div
              onClick={() => navigate("/detailactivity")}
              className="activity-item"
            >
              <div className="inline-flex">
                <h5>Glamping (3 Ticket) </h5>
                <p>24/01/2024</p>
              </div>
            </div>

            <div
              onClick={() => navigate("/detailactivity")}
              className="activity-item"
            >
              <div className="inline-flex">
                <h5>Entrance Ticket (1 Ticket)</h5>
                <p>24/01/2024</p>
              </div>
            </div>

            <div
              onClick={() => navigate("/detailactivity")}
              className="activity-item"
            >
              <div className="inline-flex">
                <h5>Concert Ticket (4 Ticket)</h5>
                <p>24/01/2024</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Profile;

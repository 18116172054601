import "./App.css";
import { Fragment } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Landing from "../src/pages/Landing/Landing";
import Login from "../src/pages/Login/Login";
import Register from "./pages/Register/Register";
import DetailPackage from "./pages/DetailPackage/DetailPackage";
import Summary from "./pages/Summary/Summary";
import TestFetchData from "./pages/TestFetchData/TestFetchData";
import Payment from "./pages/Payment/Payment";
import VA from "./pages/VA/VA";
import Profile from "./pages/Profile/Profile";
import Ticket from "./pages/Ticket/Ticket";
import TicketPayment from "./pages/TicketPayment/TicketPayment";
import VATicket from "./pages/VATicket/VATicket";
import DetailActivity from "./pages/DetailActivity/DetailActivity";
import HutanPage from "./pages/HutanPage/HutanPage";
import SungaiPage from "./pages/SungaiPage/SungaiPage";
import BudayaPage from "./pages/BudayaPage/BudayaPage";
import HistoricalPage from "./pages/HistoricalPage/HistoricalPage";
import GunungPage from "./pages/GunungPage/GunungPage";
import Xfactor from "./pages/XfactorPage/Xfactor";
import Merchandise from "./pages/Merchandise/Merchandise";

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/detailPackage" element={<DetailPackage />} />
        <Route path="/summary" element={<Summary />} />
        <Route path="/fetchData" element={<TestFetchData />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/va-payment" element={<VA />} />
        <Route path="/va-payment-ticket" element={<VATicket />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/ticket" element={<Ticket />} />
        <Route path="/ticketpayment" element={<TicketPayment />} />
        <Route path="/detailactivity" element={<DetailActivity />} />
        <Route path="/forest-page" element={<HutanPage />} />
        <Route path="/river-page" element={<SungaiPage />} />
        <Route path="/culture-page" element={<BudayaPage />} />
        <Route path="/historical-page" element={<HistoricalPage />} />
        <Route path="/mountain-page" element={<GunungPage />} />
        <Route path="/xfactor-page" element={<Xfactor />} />
        <Route path="/merchandise" element={<Merchandise />} />
      </Routes>
    </Router>
  );
};

export default App;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Ticket.css";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

const Ticket = () => {
  const navigate = useNavigate();
  let [total, setTotal] = useState(15000);

  let [ticket, setTicket] = useState(1);

  function calculate(ticket) {
    total = ticket * 15000;
    return setTotal(total);
  }

  function incrementCount() {
    ticket = ticket + 1;
    setTicket(ticket);
    calculate(ticket);
  }

  function decrementCount() {
    ticket = ticket - 1;
    setTicket(ticket);
    calculate(ticket);
  }
  return (
    <section className="ticket section container">
      <div className="ticketMaxWidth">
        <h3 onClick={() => navigate("/")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Entry Ticket</h1>
        <p>
          Pembelian tiket per orang, anak diatas usia 3 tahun dikenakan tiket
          masuk.
        </p>

        <div className="ticket-detail">
            <h4>Jumlah Tiket</h4>
          <section className="ticketCounter container">
            <button
              className="btnDecreament"
              onClick={decrementCount}
              disabled={ticket === 1}
            >
              -
            </button>
            <span className="ticket"> {ticket}</span>
            <button className="btnIncrement" onClick={incrementCount}>
              +
            </button>
          </section>
          <h2> Total: Rp {total},-</h2>
          {/* <img src={MandiriLogo} alt="" /> */}
          {/* <span>Bank Mandiri</span>
          <div className="no-va">
            <h3>7710 9201 1112 23</h3>
          </div> */}
          <button onClick={() => navigate("/ticketpayment")} className="btn">
            Book Ticket
          </button>
        </div>
      </div>
    </section>
  );
};

export default Ticket;

import React, { useEffect } from "react";
import "./home.css";
import { useNavigate } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";

const Home = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="home">
      <div className="secContainer container">
        <div className="homeText">
          <h1 data-aos="fade-up" className="title">
            Kalsel Park
          </h1>

          <p data-aos="fade-up" data-aos-duration="2500" className="subTitle">
            Escape to the world of comfort and tranquility, where adventure
            meets relaxation
          </p>

          <button className="btn">
            <a onClick={() => navigate("/ticket")} href="">
              Buy Ticket
            </a>
          </button>
        </div>

        <div className="homeCard grid">
          <div
            data-aos="fade-right"
            data-aos-duration="2000"
            className="locationDiv"
          >
            <label htmlFor="location">Activity</label>
            <input type="text" placeholder="Search Activity" />
          </div>

          {/* <div className="distDiv">
            <label htmlFor="distance">Distance</label>
            <input type="text" placeholder="Distance" />
          </div>

          <div className="priceDiv">
            <label htmlFor="price">Price</label>
            <input type="text" placeholder="Price" />
          </div> */}

          <button data-aos="fade-left" data-aos-duration="2000" className="btn">
            Search
          </button>
        </div>
      </div>
    </section>
  );
};

export default Home;

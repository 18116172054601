import React, { useEffect } from "react";
import "./banner.css";
import { useNavigate } from "react-router-dom";
// import sungai from "../../assets/sungai-page.jpeg";
// import Aos from "aos";
// import "aos/dist/aos.css";

const Banner = (props) => {
  const navigate = useNavigate();

  return (
    <section className="banner"
      style={{
        top: "2rem",
        height: "30vh",
        background: `linear-gradient(rgba(33, 33, 33, 0.522), rgba(33, 33, 33, 0.522)),
        url(${props.img})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "auto",
        position: "relative",
      }}

      // style={{ width: 128, height: 193, backgroundImage: `url(${imageUrl})` }}
      //  className="banner"
    >
      {/* <img src={sungai} alt="" /> */}
      <div className="secContainer container">
        <div className="homeText">
          <h1 className="title">{props.title}</h1>
        </div>
      </div>
    </section>
  );
};

export default Banner;

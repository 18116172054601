import React from "react";
import "./va.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import MandiriLogo from "../../assets/mandiri-logo.webp";
import {
  faArrowLeft,
  // faPerson,
  //   faMoon,
  //   faToilet,
  //   faBed,
  //   faTemperatureLow,
  // faHouseLock,
} from "@fortawesome/free-solid-svg-icons";

const VA = () => {
  const navigate = useNavigate();
  return (
    <section className="va section container">
      <div className="va-paymentMaxWidth">
        <h3 onClick={() => navigate("/payment")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Virtual Account</h1>

        <div className="va-detail">
          <img src={MandiriLogo} alt="" />
          <span>Bank Mandiri</span>
          <div className="no-va">
            <h3>7710 9201 1112 23</h3>
          </div>
          <button onClick={() => navigate("/")} className="btn">
            Check
          </button>
        </div>
      </div>
    </section>
  );
};

export default VA;

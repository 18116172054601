import React from "react";
import Home from "../../components/Home/Home";
import Popular from "../../components/Popular/Popular";
import Offer from "../../components/Offer/Offer";
import About from "../../components/About/About";
import Blog from "../../components/Blog/Blog";
import Footer from "../../components/Footer/Footer";
import Navigation from "../../components/Navbar/Navigation";
import Geoecoculture from "../../components/Geoecoculture/Geoecoculture";

const Landing = () => {
  return (
    <>
      <Navigation />
      <Home />
      <Geoecoculture />
      <Popular />
      <Offer />
      <About />
      <Blog />
      <Footer />
    </>
  );
};

export default Landing;

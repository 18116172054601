import React, { useState, useEffect } from "react";
import axios from "axios";
import PokemonList from "./pokemonList";
import './pokemon.css'

const baseURL = "https://pokeapi.co/api/v2/pokemon/";

const TestFetchData = () => {
  const [pokemon, setPokemon] = useState([]);

  useEffect(() => {
    axios.get(baseURL).then((res) => {
      setPokemon(res.data.results.map((pkmn) => pkmn.name));
    });
  },[]);

  console.log(pokemon);

  return (
    <div>
      <h1 className="judul">Pokemon</h1> 
      <PokemonList pokemon={pokemon} />
    </div>
  );
};

export default TestFetchData;

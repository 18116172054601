import React from "react";
import "./ticketPayment.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

import BcaLogo from "../../assets/bca-logo.png";
import MandiriLogo from "../../assets/mandiri-logo.webp";
import KalselbankLogo from "../../assets/bank-kalsel.webp";

const TicketPayment = () => {
  const navigate = useNavigate();
  return (
    <section className="ticketPayment section container">
      <div className="ticketPayMaxWidth">
        <h3 onClick={() => navigate("/ticket")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Ticket Payment</h1>
        <div className="summaryTicket-detail">
          <div className="detailPayment">
            <span>3 Ticket(s)</span>
           
            <hr />
            <span>Total</span>
            <p style={{ fontWeight: "bold" }}>Rp 45.000,-</p>
          </div>
        </div>

        <div className="paymentTicket-detail">
          <div className="va">
            <p>
              {" "}
              Pembayaran dilakukan melalui virtual account, pilih salah satu
              bank yang tersedia.
            </p>

            <div className="bank">
              <img src={MandiriLogo} alt="" />
              <label className="labelva" htmlFor="va-mandiri">
                Bank Mandiri
              </label>
              <input
                type="radio"
                name="va"
                value="va-mandiri"
                id="va-mandiri"
              />{" "}
            </div>
            <div className="bank">
              <img src={BcaLogo} alt="" />
              <label className="labelva" htmlFor="va-bca">
                Bank BCA
              </label>
              <input type="radio" name="va" value="va-bca" id="va-bca" />{" "}
            </div>
            <div className="bank">
              {" "}
              <img src={KalselbankLogo} alt="" />
              <label className="labelva" htmlFor="va-kalsel">
                Bank Kalsel
              </label>
              <input type="radio" name="va" value="va-kalsel" id="va-kalsel" />{" "}
            </div>
            <button onClick={() => navigate("/va-payment-ticket")} className="btn">
              Next
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TicketPayment;

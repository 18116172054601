import React, { useState } from "react";
import "./Navigation.css";
import { SiYourtraveldottv } from "react-icons/si";
import { AiFillCloseCircle } from "react-icons/ai";
import { TbGridDots } from "react-icons/tb";
import kalselLogo from "../../assets/kalsel-logo.png";
import { useNavigate } from "react-router-dom";
// import { useNavigate } from "react-router-dom";

const Navigation = () => {
  const navigate = useNavigate();
  const [active, setActive] = useState("navBar");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const showNav = () => {
    setActive("navBar activeNavbar");
  };

  const addBg = () => {
    if (window.scrollY >= 10) {
      setTransparent("header activeHeader");
    } else {
      setTransparent("header");
    }
  };
  window.addEventListener("scroll", addBg);

  const removeNavbar = () => {
    setActive("navBar");
  };

  const [transparent, setTransparent] = useState("header");

  return (
    <section className="navBarSection">
      <div className={transparent}>
        <div className="logoDiv" onClick={() => navigate("/")}>
          <a className="logo">
            <h1 className="flex">
              {/* <SiYourtraveldottv className="icon" /> */}
              <img src={kalselLogo} alt="" className="iconLogo" />
              Kalsel Park
            </h1>
          </a>
        </div>

        <div className={active}>
          <ul className="navLists flex">
            <li className="navItem">
              <a onClick={() => navigate("/")} href="" className="navLink">
                Home
              </a>
            </li>

            <li className="navItem">
              <a
                onClick={() => navigate("/ticket")}
                href=""
                className="navLink"
              >
                Ticket
              </a>
            </li>

            <li className="navItem">
              <a href="" className="navLink">
                Events
              </a>
            </li>

            <li className="navItem">
              <a href="" onClick={() => navigate("/merchandise")} className="navLink">
                Merchandise
              </a>
            </li>

            <li className="navItem">
              <a href="" className="navLink">
                News
              </a>
            </li>

            <li className="navItem">
              <a href="" className="navLink">
                About
              </a>
            </li>

            <div className="headerBtns flex">
              {isLoggedIn == true ? (
                <>
                  {" "}
                  <button
                    onClick={() => navigate("/login")}
                    className="btn loginBtn"
                  >
                    <a>Login</a>
                  </button>
                  <button onClick={() => navigate("/register")} className="btn">
                    <a>Register</a>
                  </button>
                </>
              ) : (
                <button onClick={() => navigate("/profile")} className="btn">
                  <a>Profile</a>
                </button>
              )}
            </div>
          </ul>

          <div onClick={removeNavbar} className="closeNavbar">
            <AiFillCloseCircle className="icon" />
          </div>
        </div>

        <div onClick={showNav} className="toggleNavbar">
          <TbGridDots className="icon" />
        </div>
      </div>
    </section>
  );
};

export default Navigation;

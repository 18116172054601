import React from "react";
import "./payment.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BcaLogo from "../../assets/bca-logo.png";
import MandiriLogo from "../../assets/mandiri-logo.webp";
import KalselbankLogo from "../../assets/bank-kalsel.webp";
import {
  faArrowLeft,
  faPerson,
  faMoon,
  // faToilet,
  // faBed,
  // faTemperatureLow,
  // faHouseLock,
} from "@fortawesome/free-solid-svg-icons";

const Payment = () => {
  const navigate = useNavigate();
  return (
    <section className="payment section container">
      <div className="detailPaymentMaxwidth">
        <h3 onClick={() => navigate("/summary")}>
          {" "}
          <FontAwesomeIcon icon={faArrowLeft} /> Back
        </h3>
        <h1>Payment</h1>

        <div className="detailPayment">
          <span>Booker Information</span>
          <p>Wildan Harits</p>
          <p>+62 851 2345 678</p>
          <p>wildanharits@gmail.com</p>
          <hr />
          <p>
            2 Person(s) <FontAwesomeIcon className="icon" icon={faPerson} />
          </p>
          <p>
            2 Night(s) <FontAwesomeIcon className="icon" icon={faMoon} />
          </p>
          <hr />
          <span>Detail Payment</span>
          <p style={{ fontWeight: "bold" }}>Rp 300000,-</p>
        </div>

        <div className="va">
          <p>
            {" "}
            Pembayaran dilakukan melalui virtual account, pilih salah satu bank
            yang tersedia.
          </p>

          <div className="bank">
            <img src={MandiriLogo} alt="" />
            <label className="labelva" htmlFor="va-mandiri">
              Bank Mandiri
            </label>
            <input type="radio" name="va" value="va-mandiri" id="va-mandiri" />{" "}
          </div>
          <div className="bank">
            <img src={BcaLogo} alt="" />
            <label className="labelva" htmlFor="va-bca">
              Bank BCA
            </label>
            <input type="radio" name="va" value="va-bca" id="va-bca" />{" "}
          </div>
          <div className="bank">
            {" "}
            <img src={KalselbankLogo} alt="" />
            <label className="labelva" htmlFor="va-kalsel">
              Bank Kalsel
            </label>
            <input type="radio" name="va" value="va-kalsel" id="va-kalsel" />{" "}
          </div>
          <button onClick={() => navigate("/va-payment")} className="btn">
            Next
          </button>
        </div>
      </div>
    </section>
  );
};

export default Payment;

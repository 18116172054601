import "./hutanPage.css";
import Navigation from "../../components/Navbar/Navigation";
import React, { useEffect } from "react";
import video from "../../assets/video-kalsel.mp4";
import videofauna from "../../assets/fauna.mp4";
// import Aos from "aos";
// import "aos/dist/aos.css";
import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import img1 from "../../assets/fauna/bekantan.jpeg";
import img2 from "../../assets/fauna/beruang-madu.jpeg";
import img3 from "../../assets/fauna/bintorung.avif";
import img4 from "../../assets/fauna/rusa-sambar.jpeg";

import img1f from "../../assets/flora/shorea.jpeg";
import img2f from "../../assets/flora/ulin.jpeg";
import ForestPackage from "../../components/ForestPackage/ForestPackage";

const Data = [
  {
    id: 1,
    imgSrc: img1,
    descTitle: "Bekantan",
    location: "Flora & Fauna of Kalimantan",
    grade: "unknown",
    //   navigation: "/forest-page",
  },
  {
    id: 2,
    imgSrc: img2,
    descTitle: "Beruang Madu",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/river-page",
  },
  {
    id: 3,
    imgSrc: img3,
    descTitle: "Bintorung",
    location: "Kalimantan Selatan",
    grade: " unknown",
    //   navigation: "/mountain-page",
  },
  {
    id: 4,
    imgSrc: img4,
    descTitle: "Rusa Sambar",
    location: "Kalimantan Selatan",
    grade: "unknown",
    //   navigation: "/culture-page",
  },
];

const DataFlora = [
    {
      id: 1,
      imgSrc: img1f,
      descTitle: "Shorea",
      location: "Flora & Fauna of Kalimantan",
      grade: "unknown",
      //   navigation: "/forest-page",
    },
    {
      id: 2,
      imgSrc: img2f,
      descTitle: "Euzdexelon SW (Ulin)",
      location: "Kalimantan Selatan",
      grade: "unknown",
      //   navigation: "/river-page",
    },
  ];

const HutanPage = () => {
  const navigate = useNavigate();
  return (
    <>
      <Navigation />
      <div className="header"></div>
      <section className="forest section">
        <div className="secContainer">
          <div className="videoCard-fauna container">
            <div className="cardContent grid">
              <div
                // data-aos="fade-right"
                // data-aos-duration="3000"
                className="cardText"
              >
                <h2>Fauna</h2>
                <p>
                  Lorem ipsum dolor sit amet ipsum dolor sit amet ipsum dolor
                  sit amet.
                </p>
              </div>

              <div
                // data-aos="fade-left"
                // data-aos-duration="3000"
                className="cardVideo"
              >
                <video
                  src={videofauna}
                  autoPlay
                  loop
                  muted
                  type="video/mp4"
                ></video>
              </div>
            </div>
          </div>

          <section className="fauna container">
            <div className="secContainer">
              <div className="secHeader flex">
                <div className="textDiv">
                  <h2 className="secTitle">Our Endemic Fauna</h2>
                  <p>
                    Lorem Ipsum dolor sit amet, concestur adipiscing elit. Urna,
                    tortor tempus.
                  </p>
                </div>
              </div>
              <div className="mainContent grid">
                {Data.map(({ id, imgSrc, descTitle, location, grade }) => {
                  return (
                    <div className="singleDestination">
                      <div
                        className="destImage"
                        // onClick={() => navigate(navigation)}
                      >
                        <img src={imgSrc} alt="Image Title" />

                        <div className="overlayInfo">
                          <h3>{descTitle}</h3>
                          <p>{location}</p>
                          {/* <BsArrowRightShort className="icon" /> */}
                        </div>
                      </div>

                      <div className="destFooter">
                        {/* <div className="number">0{id}</div> */}

                        <div className="destText flex">
                          <h6>{descTitle}</h6>
                          {/* <span className="flex">
                      <span className="dot">
                        <BsDot className="icon" />
                      </span>
                      Dot
                    </span> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <div className="articleFauna container">
            <h4>Fauna of Kalimantan</h4>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?
            </p>
          </div>

          <div className="videoCard-flora container">
            <div className="cardContent grid">
              <div
                // data-aos="fade-left"
                // data-aos-duration="3000"
                className="cardVideo"
              >
                <video src={video} autoPlay loop muted type="video/mp4"></video>
              </div>

              <div
                // data-aos="fade-right"
                // data-aos-duration="3000"
                className="cardText"
              >
                <h2>Flora</h2>
                <p>
                  Lorem ipsum dolor sit amet ipsum dolor sit amet ipsum dolor
                  sit amet.
                </p>
              </div>
            </div>
          </div>

          <section className="fauna container">
            <div className="secContainer">
              <div className="secHeader flex">
                <div className="textDiv">
                  <h2 className="secTitle">Our Endemic Flora</h2>
                  <p>
                    Lorem Ipsum dolor sit amet, concestur adipiscing elit. Urna,
                    tortor tempus.
                  </p>
                </div>
              </div>
              <div className="mainContent mainContentflora grid">
                {DataFlora.map(({ id, imgSrc, descTitle, location, grade }) => {
                  return (
                    <div className="singleDestination">
                      <div
                        className="destImage"
                        // onClick={() => navigate(navigation)}
                      >
                        <img src={imgSrc} alt="Image Title" />

                        <div className="overlayInfo">
                          <h3>{descTitle}</h3>
                          <p>{location}</p>
                          {/* <BsArrowRightShort className="icon" /> */}
                        </div>
                      </div>

                      <div className="destFooter">
                        {/* <div className="number">0{id}</div> */}

                        <div className="destText flex">
                          <h6>{descTitle}</h6>
                          {/* <span className="flex">
                      <span className="dot">
                        <BsDot className="icon" />
                      </span>
                      Dot
                    </span> */}
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </section>

          <div className="articleFlora container">
            <h4>Flora of Kalimantan</h4>
            <p>
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
              quae ab illo inventore veritatis et quasi architecto beatae vitae
              dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit
              aspernatur aut odit aut fugit, sed quia consequuntur magni dolores
              eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam
              est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
              velit, sed quia non numquam eius modi tempora incidunt ut labore
              et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima
              veniam, quis nostrum exercitationem ullam corporis suscipit
              laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem
              vel eum iure reprehenderit qui in ea voluptate velit esse quam
              nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo
              voluptas nulla pariatur?
            </p>
          </div>
        </div>
      </section>
                
    <ForestPackage/>

    </>
  );
};

export default HutanPage;

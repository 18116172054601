import React, { useEffect } from "react";
import "./blog.css";
import { BsArrowRightShort } from "react-icons/bs";
import img1 from "../../assets/news1.jpeg";
import img2 from "../../assets/news2.jpeg";

import Aos from "aos";
import "aos/dist/aos.css";

const Posts = [
  {
    id: 1,
    postImage: img1,
    title: "Peresmian Kalsel Park oleh Muhammad Helmi",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
  {
    id: 2,
    postImage: img2,
    title: "Kalsel Park, The Beauty of Kalimantan",
    desc: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ",
  },
];

const Blog = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="blog container section">
      <div className="secContainer">
        <div className="secIntro">
          <h2 data-aos="fade-up" data-aos-duration="2000" className="secTitle">
            Kalsel Park News
          </h2>
          <p data-aos="fade-up" data-aos-duration="2500">
            Our News and Blog, all about Kalsel Park and more.
          </p>
        </div>

        <div className="mainContainer grid">
          {Posts.map(({ id, postImage, title, desc }) => {
            return (
              <div data-aos="fade-up" data-aos-duration="2000" className="singlePost grid">
                <div className="imgDiv">
                  <img src={postImage} alt={title} />
                </div>

                <div className="postDetails">
                  <h3 data-aos="fade-up" data-aos-duration="2000">{title}</h3>
                  <p data-aos="fade-up" data-aos-duration="2500">{desc}</p>
                </div>
                <a data-aos="fade-up" data-aos-duration="3000" href="" className="flex">
                  Read More...
                  <BsArrowRightShort className="icon" />
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Blog;

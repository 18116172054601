import React, { useEffect } from "react";
import "./geoecoculture.css";

import { BsArrowLeftShort } from "react-icons/bs";
import { BsArrowRightShort } from "react-icons/bs";
import { BsDot } from "react-icons/bs";
import img1 from "../../assets/sungai-1.avif";
import img2 from "../../assets/hutan-1.jpeg";
import img3 from "../../assets/gunung-1.avif";
import img4 from "../../assets/budaya-1.avif";
import img5 from "../../assets/history-1.avif";
import img6 from "../../assets/xfactor-1.avif";

import img1g from "../../assets/geopark-logo.jpeg";
import img2g from "../../assets/kalsel.jpeg";
import img3g from "../../assets/kemenkraf.jpeg";

import { useNavigate } from "react-router-dom";

import Aos from "aos";
import "aos/dist/aos.css";

const Data = [
  {
    id: 1,
    imgSrc: img1g,
    descTitle: "Geo Tourism",
    location: "Flora & Fauna of Kalimantan",
    grade: "unknown",
    navigation: "/",
  },
  {
    id: 2,
    imgSrc: img3g,
    descTitle: "Eco Tourism",
    location: "Kalimantan Selatan",
    grade: "unknown",
    navigation: "/",
  },
  {
    id: 3,
    imgSrc: img2g,
    descTitle: "Culture Tourism",
    location: "Kalimantan Selatan",
    grade: " unknown",
    navigation: "/",
  },
];

const Geoecoculture = () => {
  const navigate = useNavigate();
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  return (
    <section className="geoecoculture section container">
      <div className="secContainer">
        <div className="secHeader flex">
          <div className="textDiv">
            <h2 className="secTitle">Geo. Eco. Culture</h2>
            <p>
              Lorem Ipsum dolor sit amet, concestur adipiscing elit. Urna,
              tortor tempus.
            </p>
          </div>
        </div>
        <div className="mainContent grid">
          {Data.map(
            ({ id, imgSrc, descTitle, location, grade, navigation }) => {
              return (
                <div data-aos="fade-up" className="singleDestination">
                  <div
                    className="destImage"
                    onClick={() => navigate(navigation)}
                  >
                    <img src={imgSrc} alt="Image Title" />

                    <div className="overlayInfo">
                      <h3>{descTitle}</h3>
                      <p>{location}</p>
                      {/* <BsArrowRightShort className="icon" /> */}
                    </div>
                  </div>
                  <div className="destFooter">
                    {/* <div className="number">0{id}</div> */}

                    <div className="destText flex">
                      <h6>{descTitle}</h6>
                      {/* <span className="flex">
                      <span className="dot">
                        <BsDot className="icon" />
                      </span>
                      Dot
                    </span> */}
                    </div>
                  </div>
                  <h4>{descTitle}</h4>
                </div>
              );
            }
          )}
        </div>
      </div>
    </section>
  );
};

// const Popular = () =>{
//   return(
//     <section className="popular section container">
//       <div className="secContainer">
//       <div className="secHeader flex">
//         <div className="textDiv">
//           <h2 className="secTitle">
//             Popular Activity
//           </h2>
//           <p>
//             Lorem Ipsum dolor sit amet.
//           </p>
//         </div>
//         <div className="iconsDiv flex">
//           <BsArrowLeftShort className="icon leftIcon"/>
//           <BsArrowRightShort className="icon"/>
//         </div>
//       </div>

//       <div className="mainContent grid">
//         <div className="singleDestination">
//           <div className="destImage">
//             <img src={img1} alt="Image Title" />
//             <div className="overlayInfo">
//               <h3>Test</h3>
//               <p>
//                 Lorem Ipsum Dolor sit amet.
//               </p>

//               <BsArrowRightShort className="icon" />
//             </div>
//           </div>
//           <div className="destFooter">
//             <div className="number">
//               01
//             </div>
//             <div className="destText flex">
//               <h6>Tahura</h6>
//               <span className="flex">
//                 <span className="dot">
//                   <BsDot className="icon"/>
//                 </span>
//                 Dot
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>
//       </div>
//     </section>
//   )
// }

export default Geoecoculture;

import React from "react";
import "./login.css";
import kalselLogo from "../../assets/kalsel-logo.png";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const navigate = useNavigate();
  return (
    <section className="login section">
      <div className="loginCard container">
        <div className="logoDiv" onClick={() => navigate("/")}>
          <a className="logo">
            <h1 className="flex">
              <img src={kalselLogo} alt="" className="iconLogo" />
              Kalsel Park
            </h1>
          </a>
        </div>
        <div className="cardContent grid">
          <div className="cardText">
            <h3>Login</h3>
            <p>
              Lorem ipsum dolor sit amet ipsum dolor sit amet ipsum dolor sit
              amet.
            </p>
          </div>

          <div className="cardLogin grid">
            <div className="emailDiv">
              <label htmlFor="email">Email</label>
              <input type="text" placeholder="Email" />
            </div>

            <div className="passwordDiv">
              <label htmlFor="password">Password</label>
              <input type="password" placeholder="Password" />
            </div>
          </div>
          <button className="btn">Login</button>
        </div>
      </div>
    </section>
  );
};

export default Login;
